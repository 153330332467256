/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, useState, useEffect, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { builder } from "@builder.io/react";
import configs from "./config";
builder.init(configs.builerIOApiKey);

const HomePage = lazy(() => import("src/pages/homePage/HomePage"));
const ContactPage = lazy(() => import("src/pages/contact/Contact"));
const PrivacyPage = lazy(() => import("src/pages/privacy/Privacy"));
const TermsPage = lazy(() => import("src/pages/terms/Terms"));
const RequestDemoPage = lazy(() => import("src/pages/requestDemo/RequestDemo"));
const PageNotFound = lazy(() => import("src/pages/pageNotFound/pageNotFound"));
const Broker = lazy(() => import("src/components/broker/broker"));
const Carrier = lazy(() => import("src/components/carrier/Carrier"));
const ServiceProvider = lazy(
  () => import("src/components/serviceProvider/ServiceProvider")
);
const Shipper = lazy(() => import("src/components/shipper/Shipper"));
const Solution = lazy(() => import("src/components/solutions/Solution"));
const SolutionCarrier = lazy(
  () => import("src/components/solutions/carrier/Carrier")
);
const Blogs = lazy(() => import("src/pages/blog/Blog"));
const Pricing = lazy(() => import("src/pages/pricing/Pricing"));
const ServiceAgreement = lazy(
  () => import("src/pages/ServiceLeveLAgreement/SLAgreement")
);
const BlogDetails = lazy(() => import("src/pages/blog/details/BlogDetails"));
const SamsaraIntegration = lazy(
  () => import("src/pages/integration/samsara/Samsara")
);
const Motive = lazy(() => import("src/pages/integration/motive/Motive"));

const Verizon = lazy(() => import("src/pages/integration/verizon/Verizon"));
const Provider = lazy(() => import("src/pages/provider/Provider"));
const SolutionBroker = lazy(
  () => import("src/components/solutions/broker/Broker")
);
const ShipperSolution = lazy(
  () => import("src/components/solutions/shipper/Shipper")
);

const AboutUs = lazy(() => import("src/pages/aboutUs/AboutUs"));
const ProviderTerms = lazy(() => import("src/pages/terms/provider/Terms"));
const PageTitleUpdater = lazy(
  () => import("src/components/pageTitleUpdater/PageTitleUpdater")
);
const GetStarted = lazy(() => import("src/pages/getStarted/getStarted"));

export interface Blog {
  image: string;
  description: string;
  newField?: string;   
  blogContent: string | any;
  title: string;
  slug: string;
  block: any
}
function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
    window.history.scrollRestoration = "manual";
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [error, setError] = useState<any>(null);
  /* eslint-disable @typescript-eslint/no-unused-vars */

  const fetchBlogs = async () => {
    builder
      .getAll("blogs", {
        options: {
          noTraverse: true,
          fields: ["data"],
        },
      })
      .then((fetchedBlogs) => {
        const filteredBlogs: any = fetchedBlogs.map((blog) => blog.data);
        setBlogs(filteredBlogs);
      })
      .catch((err) => console.error("Error fetching blog articles:", err));
  };

  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="overflow-x-hidden">
      <Suspense fallback="Loading...">
        <ScrollToTop />
        <PageTitleUpdater />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="contact" element={<ContactPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="request-demo" element={<RequestDemoPage />} />
          <Route path="customers/carrier" element={<Carrier />} />
          <Route path="customers/broker" element={<Broker />} />
          <Route path="customers/shipper" element={<Shipper />} />
          <Route path="customers/provider" element={<ServiceProvider />} />
          <Route path="solutions" element={<Solution />}></Route>
          <Route path="/solutions/carrier" element={<SolutionCarrier />} />
          <Route path="solutions/broker" element={<SolutionBroker />} />
          <Route path="solutions/shipper" element={<ShipperSolution />} />
          <Route path="solutions/provider" element={<Provider />} />
          <Route path="/blogs" element={<Blogs blogs={blogs} />} />
          <Route path="blog/:blog" element={<BlogDetails blogs={blogs} />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="sla" element={<ServiceAgreement />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/samsara-eld-tms" element={<SamsaraIntegration />} />
          <Route path="/motive-eld" element={<Motive />} />
          <Route path="/verizon-eld-tms" element={<Verizon />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/registration/:plan" element={<GetStarted />} />
          <Route path="/provider/terms" element={<ProviderTerms />} />
          <Route />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
